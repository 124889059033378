import React from 'react';
import {graphql, Link} from "gatsby";
import Layout from "../../Common/Container";
import {Breadcrumbs, CardContent, Chip, Container, Grid} from "@material-ui/core";
import {StaticImage} from "gatsby-plugin-image";
import Img from "gatsby-image"
import HomeIcon from "@material-ui/icons/Home";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(({palette, ...theme}) => ({
  card: {
    boxShadow: "12px 0 10px -2px #f7f7f7, -12px 0 10px -2px #f7f7f7",
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    marginTop: '2px',
    width: 20,
    height: 20,
  },
}));

const VideosList = ({data}) => {
  const classes = useStyles();
  const {nodes} = data.allVideosJson;
  const {videosJson} = data
  const featuredImage = videosJson.image.src.childImageSharp.fluid;
  return (
    <Layout>
      <section className="section pt-20">
        <Container maxWidth="lg">
          <div className="pt-10">
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/" className={classes.link}>
                  <HomeIcon className={classes.icon}/> Home
                </Link>
                <p style={{lineHeight: '1', color: "#000"}}>{videosJson.category}</p>
              </Breadcrumbs>
            <div>
              <Grid container spacing={4}>
                {nodes.map((item, key) => {
                  const image = item.image.src.childImageSharp.fluid;
                  return [
                    <Grid item xs={12} md={4} lg={4}>
                      <CardContent className='cardContent'>
                        <Link color="inherit" to={item.slug}>
                          <Img fluid={image} className='img'/>
                        </Link>
                        <div className="mt-2">
                          <Link color="inherit" to={item.slug}>
                            <h3 className="text-18 font-semibold"
                                style={{ lineHeight: '1.6 !important' }}>
                              {item.title}
                            </h3>
                          </Link>
                        </div>
                      </CardContent>
                    </Grid>
                  ];
                })}
              </Grid>
            </div>



          </div>
        </Container>
      </section>
    </Layout>
  );
};
export default VideosList;
export const query = graphql`
    query videoList($category: String) {
        allVideosJson(filter: {category: {eq: $category}}) {
            nodes {
                category
                title
                videoUrl
                slug
                image {
                    src {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                
                description
            }
        }
        videosJson(category: {eq:  $category}) {
            videoUrl
            title
            image {
                src {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            description
            category
        }
    }
`;
